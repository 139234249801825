/*
 * @Author: yangpeng
 * @Date: 2024-08-16 14:28:52
 * @LastEditors: yangpeng
 * @LastEditTime: 2024-08-30 15:38:18
 * @FilePath: \falcons_ecommerce_web\falcons_ecommerce-web\src\i18n\langs\sconcept.js
 * @Description:
 */
const ar = {
	essentials: 'إسينشالز',
	fearOfGodEssentials: 'فير أوف غاد إسينشال',
	supreme: 'سوبريم',
	vlone: 'فلون',
	newIn: 'NEW IN',
	apparel: 'التي شيرتات',
	kicks: 'الأحذية الرياضية',
	travis: 'ترافيس سكوت',
	offWhits: 'أوف وايت',
	assc: 'أنتي سوشال سوشال كلوب',
	payTitle: 'مبلغ الإيداع القابل للاسترداد لحجز مقعد في المزاد',
	payTitle1:'مبلغ الإيداع القابل للاسترداد لحجز ',
	payTitle2:'مقعد في المزاد',
	payPrice:'درهم إماراتي',
	payProduct: 'المنتَج',
	payDeposit: 'مبلغ الإيداع',
	payItem1: 'هل سيرافقك ضيف؟',
	payYes: 'نعم',
	payNo: 'لا',
	payMethod: 'طريقة الدفع',
	payCard: 'عبر البطاقة البنكية',
	payTransfer: 'عبر التحويل البنكي',
	payTip:
		'سيتم استرداد مبلغ الإيداع الخاص بك خلال 48 ساعة عمل بعد المزاد.يرجى ملاحظة أنه لا يسمح للضيوف بالمشاركة في المزايدة.',
	payTip1:'سيتم استرداد مبلغ الإيداع الخاص بك خلال 48 ساعة عمل بعد المزاد.',
	payTip2:'يرجى ملاحظة أنه لا يسمح للضيوف بالمشاركة في المزايدة.',
	payFail1: 'فشلت عملية الدفع الخاصة بك',
	payFail2: 'يُرجى التواصل مع خدمة العملاء',
	payFail3: 'تواصل معنا',
	payTrans1: 'معلومات التحويل البنكي الخاصة بفالكونز',
	payTrans2: 'اسم البنك',
	payTrans3: 'اسم الحساب',
	payTrans4: 'رقم الحساب',
	payTrans5: 'رقم الحساب المصرفي الدولي (آيبان)',
	payTrans6:
		'يجب أن يتمَّ سدادُ مبلغ الإيداع القابل للاسترداد عن طريق التحويل المصرفي باسم حساب المشارك بالمزاد حصراً. يرجى تقديم تفاصيل حسابك أدناه.',
	payTrans7: 'يرجى تقديم تفاصيل حسابك أدناه.',
	payTrans8: 'اسم بنك العميل',
	payTrans9: 'اسم حساب العميل',
	payTrans10: 'رقم الحساب المصرفي الدولي (آيبان) الخاص بالعميل',
	payTrans11: 'تأكيد',
	payTrans12: 'إلغاء',
	paySet: 'مقعد في المزاد',
	csTitle:'ترقَّبوا الافتتاح',
	csDesc:'الغاليريا، أبوظبي'
};
const en = {
	essentials: 'ESSENTIALS', 
	fearOfGodEssentials: 'FEAR OF GOD ESSENTIALS',  
	supreme: 'SUPREME',
	vlone: 'VLONE',
	newIn: 'NEW IN',
	apparel: 'APPAREL',
	csDesc:'THE GALLERIA MALL, ABU DHABI',
	csTitle:'COMING SOON',
	kicks: 'SNEAKERS',
	travis: 'TRAVIS SCOTT',
	offWhits: 'OFF WHITE',
	assc: 'ASSC',
	payTitle: 'REFUNDABLE DEPOSIT FOR INAUGURAL AUCTION',
	payTitle1:'REFUNDABLE DEPOSIT',
	payTitle2:'FOR INAUGURAL AUCTION',
	payProduct: 'Product',
	payDeposit: 'Deposit Amount',
	payPrice: 'AED',
	payItem1: 'Will you be bringing a guest?',
	payYes: 'Yes',
	payNo: 'No',
	payMethod: 'Method of payment',
	payCard: 'By Card',
	payTransfer: 'By Bank Transfer',
	payTip:
		'Your deposit amount will be refunded within 48 hours after the auction. Please note that guests are not permitted to participate in bidding.',
	payTip1:'Your deposit amount will be refunded within 48 hours after the auction.',
	payTip2:'Please note that guests are not permitted to participate in bidding.',
	payFail1: 'YOUR PAYMENT HAS FAILED',
	payFail2: 'Contact customer support.',
	payFail3: 'Contact us',
	payTrans1: 'FALCONS BANK TRANSFER INFORMATION',
	payTrans2: 'Bank name',
	payTrans3: 'Account name',
	payTrans4: 'IBAN number',
	payTrans5: 'Account number',
	payTrans6:
		'Refundable deposit payments by bank transfer must be made in the same name as the bidder account.',
	payTrans7: 'Please submit your account details below.',
	payTrans8: 'Customer bank name',
	payTrans9: 'Customer account name',
	payTrans10: 'Customer IBAN number',
	payTrans11: 'Confirm',
	payTrans12: 'Cancel',
	paySet: 'AUCTION SEAT',
};

export { ar, en };
