import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import { falconsLogo,listIcon,searchIcon,accountIcon,cartIcon,arFalconsLogo} from '@/constant/index';
const defaultState = () => {
    return {
        token: '',
        userInfo: {},//登录后用户信息
        lang: 'en',//全球化参数
        notificationId: 0,
        promoCode:'',
        notificationList: {
            data: [],
            total: 0,
        },
        cartSummary: {
          productList: [],
          productTotalNum: 0,
          totalPrice: 0,
          convertTotalPrice: 0,
          includingVatTotalPrice: 0,
          includingVatConvertTotalPrice: 0,
          convertCurrencyCode: 0
        },
        jumpMessageId: 0,
        cartType: 1,
        cartIdArray: [],
        selectedList: [],
        priceDetails: {},
        showDataLength: 0,
        verifyFlag: false,
        address: [],
        shippingMethod: 0,
        current: 0,
        orderDetails: {},
        paymentResultStatus: 100,
        selectedAddress: [],
        returnStatus: 0,
        publicKey:'',
        paymentMethod:4,
        banedAuction:false,
        isCancelMarketingMail:0,
        historySearch:[],
        allCategories: undefined,
        allBrands: undefined,
        allSConceptCategories: undefined,
        fineness: [],
    }
}
export default createStore({
    state: {
        headerFalcons: falconsLogo,
        headerList:listIcon,
        headerSearch:searchIcon,
        headerCart:cartIcon,
        headerAccount:accountIcon,
        addToCart:false,
        headerArIcon:arFalconsLogo,
        listColor: '#FFF',
        loginColor: '#FFF',
        token: '',
        userInfo: {},//登录后用户信息
        lang: 'en',//全球化参数
        notificationId: 0,
        notificationList: {
            data: [],
            total: 0,
        },
        cartSummary: {
          productList: [],
          productTotalNum: 0,
          totalPrice: 0,
          convertTotalPrice: 0,
          includingVatTotalPrice: 0,
          includingVatConvertTotalPrice: 0,
          convertCurrencyCode: 0
        },
        // notificationNum:0,
        jumpMessageId: 0,
        cartType: 1,
        cartIdArray: [],
        selectedList: [],
        priceDetails: {},
        showDataLength: 0,
        verifyFlag: false,
        address: [],
        shippingMethod: 0,
        current: 0,
        orderDetails: {},
        paymentResultStatus: 100,
        selectedAddress: [],
        returnStatus: 0,
        publicKey:'',
        promoCode:'',
        paymentMethod:4,
        banedAuction:false,
        isCancelMarketingMail:0,
        historySearch:[],
        allCategories: undefined,
        allSConceptCategories: undefined,
        allBrands: undefined,
        selectProduct: [],
        fineness: [],
    },
    getters: {
        getUserInfo(state) {
            return state.userInfo
        },
        getPromoCode(state) {
            return state.promoCode
        },
        getLang(state) {
            return state.lang
        },
        getToken(state) {
            return state.token || localStorage.getItem('userToken')
        },
        getNotificationId(state) {
            return state.notificationId
        },
        getNotificationList(state) {
            return state.notificationList
        },
        getCartSummary(state) {
          return state.cartSummary
        },
        getNotificationLessList(state) {
            if (state.notificationList?.data?.length > 3) {
                //    return state.notificationList.data.slice(0, 3);
                return {
                    total: state.notificationList.total,
                    data: state.notificationList.data.slice(0, 3)
                }
            }
            return state.notificationList
        },
        getJumpMessageId(state) {
            return state.jumpMessageId
        },
        getCartType(state) {
            return state.cartType
        },
        getCartIdArray(state) {
            return state.cartIdArray
        },
        getSelectedList(state) {
            return state.selectedList
        },
        getSelectedLessList(state) {
            return state.selectedList.length > 3 ? state.selectedList.slice(0, 3) : state.selectedList
        },
        getPriceDetails(state) {
            return state.priceDetails
        },
        getShowDataLength(state) {
            return state.showDataLength
        },
        getVerifyFlag(state) {
            return state.verifyFlag
        },
        getAddress(state) {
            return state.address
        },
        getShippingMethod(state) {
            return state.shippingMethod
        },
        getCurrentStep(state) {
            return state.current
        },
        getOrderDetails(state) {
            return state.orderDetails
        },
        getPaymentResultStatus(state) {
            return state.paymentResultStatus
        },
        // getCountryCode(state){
        //     return state.countryCode
        // }
        getSelectedAddress(state) {
            return state.selectedAddress
        },
        getReturnStatus(state) {
            return state.returnStatus
        },
        getPublicKey(state){
            return state.publicKey
        },
        getPaymentMethod(state){
            return state.paymentMethod
        },
        getBanedAuction(state){
            return state.banedAuction
        },
        getIsCancelMarketingMail(state){
            return state.isCancelMarketingMail?false:true
        },
        getHistorySearch(state){
            return state.historySearch.length? state.historySearch.slice(0,5) : state.historySearch
        },
        getAllBrands(state) {
          return state.allBrands || JSON.parse(window.sessionStorage.getItem('allBrand'))
        },
        getAllCategories(state) {
          return state.allCategories || JSON.parse(window.sessionStorage.getItem('allCategory'))
        },
        getAllSConceptCategories(state) {
          return state.allSConceptCategories || JSON.parse(window.sessionStorage.getItem('allSConceptCategories'))
        },
        getSelectProduct(state) {
            return state.selectProduct
        },
        getFineness(state) {
          return state.fineness
        }
    },
    mutations: {
        updateFalcons(state, title) {
            state.headerFalcons = title;
        },
        updatePromoCode(state, title) {
           state.promoCode = title;
        },
        updateAddToCart(state, title){
            state.addToCart = title;
        },
        updateArFalcons(state, title) {
            state.headerArIcon = title;
        },
        updateList(state, title) {
            state.headerList = title;
        },
        updateSearch(state, title) {
            state.headerSearch = title;
        },    
        updateListColor(state, title) {
            state.listColor = title;
        },     
        updateAccount(state, title) {
            state.headerAccount = title;
        },
        updateCart(state, title) {
            state.headerCart = title;
        },
        updateLoginColor(state, title) {
            state.loginColor = title;
        },
        updateUserInfo(state, obj) {
            state.userInfo = obj
        },
        updateLang(state, lang) {
            state.lang = lang
        },
        updateToken(state, token) {
            state.token = token
        },
        updateNotificationId(state, id) {
            state.notificationId = id
        },
        updateNotificationList(state, list) {
            // state.notificationList.data = null
            // state.notificationList.total = list.total
            // state.notificationList.data = list.data
            state.notificationList=list
        },
        setCartSummary(state, cartSummary) {
          state.cartSummary = cartSummary
        },
        updateJumpMessageId(state, id) {
            state.jumpMessageId = id
        },
        // updateCartList(state,list){
        //     state.cartList = list
        // },
        // updateCartPrice(state,price){
        //     state.cartPrice = price
        // },
        updateCartType(state, type) {
            state.cartType = type
        },
        updateCartIdArray(state, idArray) {
            state.cartIdArray = idArray
        },
        updateSelectedList(state, list) {
            state.selectedList = list
        },
        updatePriceDetails(state, list) {
            state.priceDetails = list
        },
        updateShowDataLength(state, length) {
            state.showDataLength = length
        },
        updateStoreVerifyFlag(state, flag) {
            state.verifyFlag = flag
        },
        updateAddress(state, list) {
            state.address = list
        },
        updateShippingMethod(state, value) {
            state.shippingMethod = value
        },
        updateCurrentStep(state, step) {
            state.current = step
        },
        updateOrderDetails(state, order) {
            state.orderDetails = order
        },
        updatePaymentResultStatus(state, status) {
            state.paymentResultStatus = status
        },
        updateSelectedAddress(state, address) {
            state.selectedAddress = address
        },
        updateReturnStatus(state, status) {
            state.returnStatus = status
        },
        //Reset all state when neccessary such as Logout,etc.
        reset(state) {
            Object.assign(state, defaultState())
        },
        updatePublicKey(state,key){
            state.publicKey = key
        },
        updatePaymentMethod(state,value){
            state.paymentMethod = value
        },
        updateBanedAuction(state,value){
            state.banedAuction = value
        },
        updateIsCancelMarketingMail(state,status){
            state.isCancelMarketingMail = status
        },
        updateHistorySearch(state,value){
            state.historySearch = value
        },
        clearHistorySearch(state){
            state.historySearch = []
        },
        setAllBrands(state, allBrands) {
          state.allBrands = allBrands
        },
        setAllCategories(state, allCategories) {
          state.allCategories = allCategories
        },
        setAllSConceptCategories(state, allSConceptCategories) {
          state.allSConceptCategories = allSConceptCategories
        },
        setSelectProduct(state, selectProduct) {
          state.selectProduct = selectProduct
        },
        updateFineness(state, fineness) {
          state.fineness = fineness
        }
    },
    actions: {
        getUserInfo(context) {
            console.log(context)
        },
    },
    modules: {
    },
    plugins: [createPersistedState(
        {
            storage: window.sessionStorage,
            reducer(val) {
                return {
                    // For persistant use
                    notificationList: val.notificationList,
                    publicKey:val.publicKey,
                    lang:val.lang,
                    historySearch:val.historySearch
                    // notificationId: val.notificationId
                }
            }
        }
    )]
})

